import React, { useState } from 'react';
import MainForm from './components/MainForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThankYou from './components/ThankYou';
import Layout from './components/Layout';

function App() {

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formdata, setFormdata] = useState('');

  return (
    <div >
      <Layout goBack={() => {
        setFormSubmitted(false);
        setFormdata('');
      }}
      >
        {!formSubmitted ? <MainForm submitSuccess={() => setFormSubmitted(true)} setFormdata={setFormdata} /> : <ThankYou formdata={formdata} />}
      </Layout>
    </div>
  );
}

export default App;
