import { AxiosInstance, AxiosPromise } from 'axios';


const apiPath = process.env.REACT_APP_API_URL || '';


export interface WhitelistData {
	walletAddress: string,
	email?: string;
	emailNotifications: boolean;
	updateRequest?: boolean;
}


export class Repository {
	constructor(private axios: AxiosInstance) { }

	submit(data: WhitelistData): AxiosPromise<void> {
		return this.axios.post(apiPath + '/register', data, {
			headers: {
				'Content-Type': 'application/json',
			}
		});
	}

	getCount(): Promise<number> {
		return this.axios.get(apiPath + '/registration-count').then(data => {
			return data.data.Count;
		})
	}
}