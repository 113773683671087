import { useEffect, useState } from 'react';
import { Repository } from './Repository';
import axios from 'axios';

const repo = new Repository(axios);


const LIMIT = 2500;

/**
 * 
 * @returns { isValid: boolean, leftSpots: number }
 */
const useWLCount = () => {


	const [count, setCount] = useState(0);

	useEffect(() => {
		repo.getCount().then((c) => setCount(c));
	}, []);

	const isValid = count < LIMIT;

	return { isValid, leftSpots: LIMIT - count };
}


export default useWLCount;