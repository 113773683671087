import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'
import TwitterShare from '../assets/twitter-share-button.png';

const ThankYou = ({ formdata }: { formdata: any }) => {

    return (
        <>
            <h1 className='text-center heading mt-5'>
                Thank you, Your wallet address has been added to the allowlist! <br />

            </h1>
            <br />
            <h4 className="heading text-center">
                { (formdata.email && formdata.allowEmailNotifications) && ('You will receive an email confirmation at: ' + formdata.email)}
            </h4>
            <div className="heading text-center center">
                <img src={TwitterShare}
                    className="mb-5 mt-5 share-tweet"
                    height="50"
                    onClick={() => window.open('http://twitter.com/intent/tweet?text=I%20just%20joined%20the%20%40ovrt_xyz%20%40_hypees%20whitelist!%20%23OVRT%20%23HYPEE%20', 'targetWindow',
                        `toolbar=no,
                                 location=no,
                                 status=no,
                                 menubar=no,
                                 scrollbars=yes,
                                 resizable=yes,
                                 width=500px,
                                 height=500px`)}
                />
            </div>

        </>

    );
};

export default ThankYou;