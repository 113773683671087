import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from "yup";
import './styles.css'
import axios from 'axios';
import { Repository, WhitelistData } from '../Repository';
import { Alert } from 'react-bootstrap';
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap';
import { ReactComponent as EthLogo } from '../assets/eth.svg';
import { ReactComponent as EmailLogo } from '../assets/email.svg';
import ReCaptchaV2 from 'react-google-recaptcha';
import useWLCount from '../useWLCount';

const LEFT_SPOT_THRESHOLD = 200;
const repo = new Repository(axios);


interface PropTypes {
	submitSuccess: () => void;
	setFormdata: (formdata: any) => void;
}

enum API_ERROR_CODES {
	'DUPLICATE_ENTRY' = 1,
	'UNKNOWN'
}
interface APIError {
	code?: API_ERROR_CODES;
	message?: string;
	alertVariant?: string;
}

const getClassname = (touched: any, error: any) => {
	if (touched) {
		if (error) {
			return 'is-invalid form-control';
		} else {
			return 'is-valid form-control';
		}
	} else {
		return 'form-control';
	}
}

const MainForm = ({ submitSuccess, setFormdata }: PropTypes) => {

	const [apiError, setApiError] = useState<APIError>({});
	const [lastValues, setLastValues] = useState<WhitelistData | null>(null);
	const [recaptchaVerified, setRecaptchaVerified] = useState(false);


	const { isValid, leftSpots } = useWLCount();

	return (
		<div className="form-wrapper">
			<h4 className="heading text-center">Welcome to the OVRT Allowlist form! </h4>
			<br />

			{!isValid ? (
				<Alert className="w-25 center" variant='danger'>
					We're out of spots right now. Come back later.
				</Alert>
			) : (
				<h5 className="heading text-center">Please provide the following information to get exclusive access to the HYPEE collection! </h5>
			)}



			<Formik
				initialValues={{ email: '', walletAddress: '', allowEmailNotifications: false, }}
				onSubmit={(values, { setSubmitting }) => {
					if (!recaptchaVerified) {
						return;
					}
					setSubmitting(true);


					const data = { walletAddress: values.walletAddress, email: values.email, emailNotifications: values.allowEmailNotifications, updateRequest: false };

					if (apiError.code === API_ERROR_CODES.DUPLICATE_ENTRY && lastValues?.walletAddress === values.walletAddress) {
						data.updateRequest = true;
					}

					setLastValues(data);

					repo.submit(data).then(() => {
						setFormdata(values);
						submitSuccess();
					}).catch((e: any) => {
						if (e.response?.data?.code === 'DUPLICATE_ENTRY') {
							setApiError({
								code: API_ERROR_CODES.DUPLICATE_ENTRY,
								message: 'Wallet address is already registered. Would you like to update email preference?',
								alertVariant: 'warning'
							})
						} else {
							setApiError({
								code: API_ERROR_CODES.UNKNOWN,
								message: 'Something went wrong, It is not your fault, try again later?',
								alertVariant: 'danger'
							})
						}
					}).finally(() => {
						setSubmitting(false);
					})

				}}
				validationSchema={yup.object().shape({
					walletAddress: yup.string().required().min(42).max(42),
					allowEmailNotifications: yup.boolean().required()
				})}
			>
				{({ isSubmitting, setFieldValue, errors, touched, values }) => (


					<Form className="form w-75">
						{leftSpots < LEFT_SPOT_THRESHOLD && (
							<Alert className="w-50 center" variant='warning'>
								Hurry, we're just left with {leftSpots} Spots.
							</Alert>
						)}

						{apiError.code && apiError.code !== API_ERROR_CODES.DUPLICATE_ENTRY && (
							<Alert className="w-50 center" variant={apiError.alertVariant}>
								{apiError.message}
							</Alert>
						)}
						<div>

							<FormGroup className='data-form  m-5 heading center'>
								<InputGroup>
									<InputGroup.Text>
										<EthLogo className='form-svg' style={{
											color: errors.walletAddress ? 'maroon' : undefined
										}} height="40px" />
									</InputGroup.Text>
									<Field
										name="walletAddress"
										placeholder="Wallet Address (Required)"
										className={getClassname(touched.walletAddress, errors.walletAddress)}
										type="text"
										disabled={!isValid}
									/>
								</InputGroup>
								<div className='w-error'>
									{touched.walletAddress && errors.walletAddress}
								</div>
							</FormGroup>

							<FormGroup className='data-form  m-5 heading center'>
								<InputGroup>
									<InputGroup.Text> <EmailLogo className='form-svg' height="40px" /></InputGroup.Text>
									<Field name="email" placeholder="Email (Optional)" className="form-control" type="email" disabled={!isValid} />
								</InputGroup>
							</FormGroup>

							<div className="m-5 center">
								<Field type="checkbox" disabled={!isValid} className={"form-check-input"} name="allowEmailNotifications" />
								<label className="form-check-label heading">
									{" "} Opt-in to email notification for NFT drops, merch, and more?
								</label>
							</div>

							{isValid && (
								<div className="text-center">
									<ReCaptchaV2
										sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY || ''}
										className="g-recaptcha"
										theme="dark"
										onChange={(token) => setRecaptchaVerified(true)}
										onExpired={() => setRecaptchaVerified(false)}
									/>
								</div>
							)}



							{
								apiError.code === API_ERROR_CODES.DUPLICATE_ENTRY
									&& values.walletAddress === lastValues?.walletAddress
									? (
										<>
											<Alert className="w-50 center" variant={apiError.alertVariant}>
												{apiError.message}
											</Alert>
											<div className="form-group m-5 center">
												<button type="submit" className="primary-button" >{isSubmitting ? "Please wait..." : "Update Details"}</button>
											</div>
										</>
									) : (
										<div className="form-group m-5 center">
											<button disabled={!isValid || isSubmitting || !recaptchaVerified} type="submit" className="primary-button" >{isSubmitting ? "Please wait..." : "Register"}</button>
										</div>
									)
							}
						</div>
					</Form>

				)}
			</Formik>

		</div>
	);
};

export default MainForm;