import React from 'react';
import { ReactComponent as DiscordLogo } from '../assets/discord.svg';
import { ReactComponent as InstagramLogo } from '../assets/instagram.svg';
import { ReactComponent as TwitterLogo } from '../assets/twitter.svg';

interface PropTypes {
	children: React.ReactNode;
	goBack: () => void;
}

const Layout = ({ children, goBack }: PropTypes) => {
	return (
		<div className="layout">

			<a href="https://ovrt.xyz">
				<div className="text-center d-flex justify-content-center" onClick={goBack}>
					<div className="header_logo  pt-5" />
				</div>
			</a>
			{children}

			<div className="footer d-flex justify-content-center">
				<a href="https://discord.gg/V25qQUzk">
					<DiscordLogo className='social' height="60px"
						color='white' />
				</a>
				<a href="https://intagram.com/ovrt_xyz">
					<InstagramLogo className='social' height="60px"
						color='white' />
				</a>
				<a href="https://twitter.com/ovrt_xyz">
					<TwitterLogo className='social' height="60px"
						color='white' />
				</a>
			</div>
		</div>
	)
}


export default Layout;